import * as React from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import {
  BrowserRouter as Router,
  useRoutes,
} from "react-router-dom";
import { CookiesProvider } from "react-cookie";


import RenewMember from './components/renewal/RenewMember';
import RegisterMember from './components/registration/RegisterMember';
import TopUpAccount from './components/topup/TopUpAccount';
import SubscriptionPurchase from './components/subscription/SubscriptionPurchase';
import BranchDetails from './components/branch/BranchDetails';
import BranchMembers from './components/branch/BranchMembers';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries




const AppWrapper = () => {

  let routes = useRoutes([
    { path: "/renew_member", element: <RenewMember /> }, // Add the renewal route
    {path: "/register_member", element: <RegisterMember />},
    {path: "/topup_account", element: <TopUpAccount />},
    {path: "/subscription_purchase", element: <SubscriptionPurchase />},
    {path: "/branch_details", element: <BranchDetails />},
    {path: "/branch_members", element: <BranchMembers />},
    // ...
  ]);
  return routes;
};




function App() {

  return (
    <CookiesProvider>
          <Router>
    <ChakraProvider>
 

      <AppWrapper  />

</ChakraProvider>
</Router>
</CookiesProvider>
  );
}

export default App;