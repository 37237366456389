import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  CircularProgress,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  Input,
  VStack,
  HStack,
  Text,
  useToast,
  SimpleGrid,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Stack,
  useColorModeValue,
  Icon,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';
import { PhoneIcon, CheckIcon, TimeIcon, StarIcon } from '@chakra-ui/icons';
import { motion } from 'framer-motion';
import axios from 'axios';
import { API_URL } from '../../utils/api/Api';

const MotionBox = motion(Box);

const SubscriptionPurchase = () => {
  const [searchParams] = useSearchParams();
  const toast = useToast();

  // States
  const [loading, setLoading] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(60);
  const [timerActive, setTimerActive] = useState(false);
  const [checkingPayment, setCheckingPayment] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const [selectedPackage, setSelectedPackage] = useState(null);

  const [formData, setFormData] = useState({
    phone: formatPhoneNumber(searchParams.get('phone_number') || ''),
    payment_phone: formatPhoneNumber(searchParams.get('phone_number') || ''),
    package_id: null,
    mno: 'Vodacom'
  });

  const [paymentStatus, setPaymentStatus] = useState({
    isProcessing: false,
    reference: '',
    isRetry: false
  });

  // Subscription packages
  const subscriptionPackages = [
    {
      id: 4,
      name: "News Subscription 1 Month",
      descr: "News Subscription 1 Month",
      amount: 2000,
      durationDays: 30
    },
    {
      id: 5,
      name: "News Subscription 3 Months",
      descr: "News Subscription 3 Months",
      amount: 6000,
      durationDays: 90
    },
    {
      id: 6,
      name: "News Subscription 6 Months",
      descr: "News Subscription 6 Months",
      amount: 12000,
      durationDays: 180
    },
    {
      id: 7,
      name: "News Subscription 12 Months",
      descr: "News Subscription 12 Months",
      amount: 24000,
      durationDays: 365
    }
  ];

  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const cardBg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const highlightColor = useColorModeValue('teal.500', 'teal.300');

  // Format phone number function
  function formatPhoneNumber(phoneNumber) {
    const cleanNumber = phoneNumber.replace(/\s+/g, '');
    if (cleanNumber.startsWith('+255')) {
      return '0' + cleanNumber.slice(4);
    }
    if (cleanNumber.startsWith('255')) {
      return '0' + cleanNumber.slice(3);
    }
    if (cleanNumber.startsWith('0')) {
      return cleanNumber;
    }
    return phoneNumber;
  }

  // Handle subscription purchase
  const handleSubscribe = async () => {
    setLoading(true);
    try {
      const payload = {
        package_id: selectedPackage.id,
        phone: formData.phone.startsWith('0') ? 
          '255' + formData.phone.slice(1) : formData.phone,
        payment_phone: formData.payment_phone.startsWith('0') ? 
          '255' + formData.payment_phone.slice(1) : formData.payment_phone,
        mno: formData.mno
      };

      const response = await axios.post(
        `${API_URL}/api/subscription/initiate_subscription`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data.status === "success") {
        setPaymentStatus({
          isProcessing: true,
          reference: response.data.reference,
          isRetry: false
        });
        setTimeRemaining(60);
        setTimerActive(true);
        
        toast({
          title: 'Payment Initiated',
          description: 'Please check your phone for payment prompt',
          status: 'info',
          duration: null
        });
      }
    } catch (error) {
      console.error('Subscription Error:', error);
      toast({
        title: 'Subscription Error',
        description: error.response?.data?.message || error.message,
        status: 'error',
        duration: 5000
      });
    } finally {
      setLoading(false);
    }
  };

  // Package Card Component
  const PackageCard = ({ pkg }) => {
    const isSelected = selectedPackage?.id === pkg.id;
    const cardVariants = {
      hover: { scale: 1.02, transition: { duration: 0.2 } },
      tap: { scale: 0.98 },
    };

    // Function to format the duration display
    const formatDuration = (days) => {
      const months = {
        30: "1 Month",
        90: "3 Months",
        180: "6 Months",
        365: "12 Months"
      };
      return months[days] || `${days / 30} Months`;
    };

    return (
      <MotionBox
        whileHover="hover"
        whileTap="tap"
        variants={cardVariants}
      >
        <Card
          h="full"
          cursor="pointer"
          onClick={() => setSelectedPackage(pkg)}
          borderColor={isSelected ? highlightColor : borderColor}
          borderWidth={2}
          bg={cardBg}
          transition="all 0.2s"
          position="relative"
          overflow="hidden"
        >
          {isSelected && (
            <Box
              position="absolute"
              top={0}
              right={0}
              bg={highlightColor}
              color="white"
              p={2}
              borderBottomLeftRadius="md"
            >
              <CheckIcon />
            </Box>
          )}
          
          <CardHeader pb={2}>
            <Stack spacing={2}>
              <Text fontSize="sm" color="gray.500">
                Subscription Plan
              </Text>
              <Heading size="md">
                {formatDuration(pkg.durationDays)}
              </Heading>
            </Stack>
          </CardHeader>

          <CardBody>
            <VStack align="start" spacing={4}>
              <Text fontSize="2xl" fontWeight="bold" color={highlightColor}>
                {pkg.amount.toLocaleString()} TZS
              </Text>
              <Stack spacing={2}>
                <Feature text={`${pkg.durationDays} days access`} />
                <Feature text="Full news access" />
                <Feature text="Premium content" />
              </Stack>
            </VStack>
          </CardBody>

          <CardFooter pt={0}>
            <Button
              w="full"
              colorScheme="teal"
              variant={isSelected ? "solid" : "outline"}
            >
              {isSelected ? "Selected" : "Select Plan"}
            </Button>
          </CardFooter>
        </Card>
      </MotionBox>
    );
  };

  // Feature Component
  const Feature = ({ text }) => (
    <HStack spacing={2}>
      <Icon as={CheckIcon} color="green.500" />
      <Text fontSize="sm">{text}</Text>
    </HStack>
  );

  return (
    <Box minH="100vh" bg={bgColor} py={[8, 12, 16]}>
      <Container maxW="7xl">
        <VStack spacing={8}>
          <Stack textAlign="center" spacing={4}>
            <Heading 
              fontSize={['2xl', '3xl', '4xl']}
              bgGradient="linear(to-r, teal.400, teal.600)"
              bgClip="text"
            >
              Choose Your Subscription Plan
            </Heading>
            <Text color="gray.500" maxW="2xl" mx="auto">
              Get unlimited access to premium news content with our flexible subscription plans
            </Text>
          </Stack>

          <SimpleGrid 
            columns={{ base: 1, md: 2, lg: 4 }} 
            spacing={6} 
            w="full"
            px={[4, 6, 8]}
          >
            {subscriptionPackages.map((pkg) => (
              <PackageCard key={pkg.id} pkg={pkg} />
            ))}
          </SimpleGrid>

          {selectedPackage && (
            <Box 
              w="full" 
              maxW="md" 
              bg={cardBg} 
              p={8} 
              borderRadius="lg" 
              boxShadow="xl"
              border="1px"
              borderColor={borderColor}
            >
              <VStack spacing={6}>
                <Heading size="md" textAlign="center">
                  Payment Details
                </Heading>

                <FormControl>
                  <FormLabel>Phone Number</FormLabel>
                  <InputGroup>
                    <InputLeftElement>
                      <PhoneIcon color="gray.500" />
                    </InputLeftElement>
                    <Input
                      value={formData.phone}
                      isReadOnly
                      bg="gray.50"
                      _readOnly={{ opacity: 0.7 }}
                    />
                  </InputGroup>
                </FormControl>

                <FormControl>
                  <FormLabel>Payment Phone Number (M-Pesa)</FormLabel>
                  <InputGroup>
                    <InputLeftElement>
                      <PhoneIcon color="gray.500" />
                    </InputLeftElement>
                    <Input
                      value={formData.payment_phone}
                      onChange={(e) => setFormData(prev => ({
                        ...prev,
                        payment_phone: formatPhoneNumber(e.target.value)
                      }))}
                      placeholder="Enter M-Pesa number"
                    />
                  </InputGroup>
                </FormControl>

                <Button
                  colorScheme="teal"
                  size="lg"
                  width="full"
                  onClick={handleSubscribe}
                  isLoading={loading}
                  disabled={!selectedPackage || !formData.payment_phone}
                  _hover={{ transform: 'translateY(-2px)', boxShadow: 'lg' }}
                  transition="all 0.2s"
                >
                  Pay {selectedPackage.amount.toLocaleString()} TZS
                </Button>

                {loading && (
                  <Text color="gray.500" fontSize="sm" textAlign="center">
                    Processing your payment...
                  </Text>
                )}
              </VStack>
            </Box>
          )}
        </VStack>
      </Container>
    </Box>
  );
};

export default SubscriptionPurchase;
