import React, { useState, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Input,
  InputGroup,
  InputLeftElement,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Avatar,
  Badge,
  Text,
  VStack,
  useColorModeValue,
  Spinner,
  Center,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';

const BranchMembers = () => {
  const location = useLocation();
  const { branchID, userID, memberID } = location.state;
  
  const [members, setMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const response = await axios.post(
          'https://potm.yangasc.africa/api/membership/list_branch_members/',
          {
            branchID,
            userID,
            memberID
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'Accept': '*/*',
              'Accept-Language': 'en-AU,en-GB;q=0.9,en-US;q=0.8,en;q=0.7',
            }
          }
        );

        if (response.data.status) {
          setMembers(response.data.responseObj.items);
        } else {
          console.error('Failed to fetch members');
        }
      } catch (error) {
        console.error('Error fetching members:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMembers();
  }, [branchID, userID, memberID]);

  const filteredMembers = useMemo(() => {
    return members.filter(member => {
      const searchString = `${member.fName} ${member.mName} ${member.lName} ${member.mobile}`.toLowerCase();
      const isActive = member.expiryStatus === 'ACTIVE';
      
      return searchString.includes(searchQuery.toLowerCase()) && 
             (activeTab === 0 ? isActive : !isActive);
    });
  }, [members, searchQuery, activeTab]);

  const tableBg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  if (isLoading) {
    return (
      <Center h="100vh">
        <Spinner 
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="teal.500"
          size="xl"
        />
      </Center>
    );
  }

  return (
    <VStack spacing={4} w="full" p={4}>
      <InputGroup w={['100%', '75%', '50%']} mx="auto">
        <InputLeftElement pointerEvents="none">
          <SearchIcon color="gray.300" />
        </InputLeftElement>
        <Input
          type="text"
          placeholder="Search by name or phone number"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </InputGroup>
      <Tabs variant="soft-rounded" colorScheme="teal" onChange={setActiveTab}>
        <TabList>
          <Tab>Wanachama Active</Tab>
          <Tab>Wanachama Inactive</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Box overflowX="auto" bg={tableBg} borderRadius="md" boxShadow="md">
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Profile</Th>
                    <Th>Name</Th>
                    <Th>Phone</Th>
                    <Th>Member ID</Th>
                    <Th>Status</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {filteredMembers.map(member => (
                    <Tr key={member.id} _hover={{ bg: 'gray.50' }}>
                      <Td>
                        <Avatar 
                          size="md" 
                          name={`${member.fName} ${member.lName}`} 
                          src={member.profileImage} 
                        />
                      </Td>
                      <Td>
                        <Text fontWeight="medium">
                          {`${member.fName} ${member.mName || ''} ${member.lName}`}
                        </Text>
                      </Td>
                      <Td>{member.mobile}</Td>
                      <Td>{member.memberID}</Td>
                      <Td>
                        <Badge 
                          colorScheme={member.expiryStatus === 'ACTIVE' ? 'green' : 'red'}
                          borderRadius="full"
                          px={2}
                        >
                          {member.expiryStatus}
                        </Badge>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          </TabPanel>
          <TabPanel>
            {/* Same table structure as above for inactive members */}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );
};

export default BranchMembers; 