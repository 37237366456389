import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Center,
  Heading,
  VStack,
  Text,
  useToast,
  Spinner,
  Grid,
  GridItem,
  Badge,
  HStack,
  Icon,
  Container,
  useColorModeValue,
  Flex,
} from '@chakra-ui/react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaMapMarkerAlt, FaUsers, FaGlobe, FaMapMarkedAlt, FaCity } from 'react-icons/fa';
import { formatPhoneNumber } from '../../utils/phonenumber/PhoneNumber';
import { API_URL } from '../../utils/api/Api';

const BranchDetails = () => {
  const [searchParams] = useSearchParams();
  const initialPhoneNumber = formatPhoneNumber(searchParams.get('phone_number') || '');
  const toast = useToast();
  const navigate = useNavigate();

  // States
  const [loading, setLoading] = useState(true);
  const [memberInfo, setMemberInfo] = useState(null);
  const [branchDetails, setBranchDetails] = useState(null);
  const [branchMembers, setBranchMembers] = useState([]);

  // Fetch member info and then branch details
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // First fetch member info
        const memberResponse = await axios.post(
          `${API_URL}/api/get-member-info/`,
          {
            filter: [
              { key: "mobileNumber", value: initialPhoneNumber, ttype: "==" }
            ]
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );

        if (memberResponse.data.responseObj.items.length > 0) {
          const member = memberResponse.data.responseObj.items[0];
          setMemberInfo(member);

          // Then fetch branch details
          const branchResponse = await axios.post(
            `${API_URL}/api/membership/list_branches/`,
            {
              branchID: member.branchID,
              userID: member.id,
              memberID: member.memberID
            },
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          );

          if (branchResponse.data.responseObj.items.length > 0) {
            setBranchDetails(branchResponse.data.responseObj.items[0]);
          }
        } else {
          toast({
            title: 'Member not found',
            description: 'No member found with this phone number',
            status: 'error',
            duration: 5000
          });
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        toast({
          title: 'Error',
          description: 'Failed to fetch information',
          status: 'error',
          duration: 5000
        });
      } finally {
        setLoading(false);
      }
    };

    if (initialPhoneNumber) {
      fetchData();
    }
  }, [initialPhoneNumber, toast]);

  useEffect(() => {
    const fetchMembers = async () => {
      if (memberInfo) {
        const membersResponse = await axios.post(
          `${API_URL}/api/membership/list_branch_members/`,
          {
            branchID: memberInfo.branchID,
            userID: memberInfo.id,
            memberID: memberInfo.memberID
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );

        if (membersResponse.data.responseObj.items.length > 0) {
          setBranchMembers(membersResponse.data.responseObj.items);
        }
      }
    };

    fetchMembers();
  }, [memberInfo]);

  if (loading) {
    return (
      <Center minH="100vh" bg="gray.50">
        <VStack spacing={6}>
          <Spinner 
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="teal.500"
            size="xl"
          />
          <Text fontSize="lg" color="gray.600">Loading branch information...</Text>
        </VStack>
      </Center>
    );
  }

  if (!branchDetails) {
    return (
      <Center minH="100vh" bg="gray.50">
        <Text fontSize="lg" color="gray.600">No branch information available</Text>
      </Center>
    );
  }

  return (
    <Box bg="gray.50" minH="100vh" py={8}>
      <Container maxW="container.lg">
        <VStack spacing={8}>
          {/* Header Card */}
          <Box 
            w="full"
            bg="white"
            borderRadius="2xl"
            overflow="hidden"
            boxShadow="xl"
          >
            {/* Gradient Header */}
            <Box 
              bg="linear-gradient(135deg, teal.500, teal.600)"
              p={8}
              color="white"
              position="relative"
              _before={{
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundImage: 'radial-gradient(circle at 20% 150%, rgba(255,255,255,0.1) 0%, transparent 50%)',
              }}
            >
              <VStack align="start" spacing={4}>
                <Flex justify="space-between" w="full" align="center" wrap="wrap" gap={4}>
                  <Heading size="lg" fontWeight="bold">
                    {branchDetails.name}
                  </Heading>
                  <Badge
                    colorScheme={branchDetails.branchStatus === 'Active' ? 'green' : 'red'}
                    p={2}
                    px={4}
                    borderRadius="full"
                    fontSize="md"
                    bg="white"
                    textTransform="capitalize"
                  >
                    {branchDetails.branchStatus}
                  </Badge>
                </Flex>
                <Text fontSize="lg" opacity={0.9}>
                  Branch Code: {branchDetails.code}
                </Text>
              </VStack>
            </Box>

            {/* Stats Grid */}
            <Grid 
              templateColumns={['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)']}
              gap={6}
              p={8}
            >
              {/* Total Members */}
              <GridItem>
                <Box
                  bg="teal.50"
                  p={6}
                  borderRadius="xl"
                  textAlign="center"
                  transition="all 0.3s"
                  _hover={{
                    transform: 'translateY(-4px)',
                    shadow: 'lg',
                  }}
                >
                  <Icon as={FaUsers} w={8} h={8} color="teal.500" mb={3} />
                  <Text color="teal.700" fontSize="lg" fontWeight="semibold" mb={2}>
                    Total Members
                  </Text>
                  <Text fontSize="3xl" fontWeight="bold" color="teal.600">
                    {branchDetails.countMembers}
                  </Text>
                </Box>
              </GridItem>

              {/* Active Members */}
              <GridItem>
                <Box
                  bg="green.50"
                  p={6}
                  borderRadius="xl"
                  textAlign="center"
                  transition="all 0.3s"
                  _hover={{
                    transform: 'translateY(-4px)',
                    shadow: 'lg',
                  }}
                >
                  <Icon as={FaUsers} w={8} h={8} color="green.500" mb={3} />
                  <Text color="green.700" fontSize="lg" fontWeight="semibold" mb={2}>
                    Active Members
                  </Text>
                  <Text fontSize="3xl" fontWeight="bold" color="green.600">
                    {branchDetails.countActiveMembers}
                  </Text>
                </Box>
              </GridItem>

              {/* Inactive Members */}
              <GridItem>
                <Box
                  bg="orange.50"
                  p={6}
                  borderRadius="xl"
                  textAlign="center"
                  transition="all 0.3s"
                  _hover={{
                    transform: 'translateY(-4px)',
                    shadow: 'lg',
                  }}
                >
                  <Icon as={FaUsers} w={8} h={8} color="orange.500" mb={3} />
                  <Text color="orange.700" fontSize="lg" fontWeight="semibold" mb={2}>
                    Inactive Members
                  </Text>
                  <Text fontSize="3xl" fontWeight="bold" color="orange.600">
                    {branchDetails.countInactiveMembers}
                  </Text>
                </Box>
              </GridItem>
            </Grid>

            {/* Location Details */}
            <Box px={8} pb={8}>
              <Box
                bg="gray.50"
                p={6}
                borderRadius="xl"
                border="1px"
                borderColor="gray.200"
              >
                <VStack align="start" spacing={6}>
                  <Heading size="md" color="gray.700" display="flex" alignItems="center">
                    <Icon as={FaMapMarkerAlt} w={6} h={6} color="teal.500" mr={2} />
                    Location Details
                  </Heading>
                  <Grid 
                    templateColumns={['1fr', '1fr 1fr']} 
                    gap={4} 
                    w="full"
                  >
                    <HStack>
                      <Icon as={FaMapMarkerAlt} color="teal.500" />
                      <Text color="gray.600">{branchDetails.location}</Text>
                    </HStack>
                    <Text color="gray.600">
                      <strong>Zone:</strong> {branchDetails.zone}
                    </Text>
                    <Text color="gray.600">
                      <strong>Region:</strong> {branchDetails.region}
                    </Text>
                    <Text color="gray.600">
                      <strong>District:</strong> {branchDetails.district}
                    </Text>
                  </Grid>
                </VStack>
              </Box>
            </Box>

            {/* View Members Button */}
            <Button
              colorScheme="teal"
              size="lg"
              width="full"
              leftIcon={<FaUsers />}
              onClick={() => {
                navigate('/branch_members', { 
                  state: { 
                    branchID: branchDetails.id,
                    userID: memberInfo.id,
                    memberID: memberInfo.memberID
                  } 
                });
              }}
              _hover={{
                transform: 'translateY(-2px)',
                boxShadow: 'lg',
              }}
              transition="all 0.2s"
            >
              Tazama Wanachama
            </Button>
          </Box>
        </VStack>
      </Container>
    </Box>
  );
};

export default BranchDetails; 