import React, { useEffect, useState } from 'react';
import {
  Box, Button, Center, FormControl, FormLabel, Heading,
   Select, VStack, Spinner, useToast, Image, Text, HStack, InputGroup, InputLeftElement, CircularProgress, CircularProgressLabel, Alert, AlertIcon, AlertTitle, AlertDescription, Divider
} from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { SearchIcon, PhoneIcon, RepeatIcon } from '@chakra-ui/icons';
import { Select as ChakraSelect, Input } from '@chakra-ui/react';
import { Select as ReactSelect } from 'chakra-react-select';
import { motion, AnimatePresence } from 'framer-motion';
import { formatPhoneNumber } from '../../utils/phonenumber/PhoneNumber';
import {API_URL} from '../../utils/api/Api';


const RegisterMember = () => {
  const [searchParams] = useSearchParams();
  const initialPhoneNumber = formatPhoneNumber(searchParams.get('phone_number') || '');


  const [formData, setFormData] = useState(() => ({
    phone: initialPhoneNumber,
    payment_phone: initialPhoneNumber,
    fName: '',
    lName: '',
    dob: '',
    gender: '',
    branchID: '',
    branchName: '',
    zone: '',
    region: '',
    district: '',
    country: 'TANZANIAN',
    mno: 'Vodacom',
    email: '',
    username: ''
  }));

  const [zones, setZones] = useState([]);
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingCities, setLoadingCities] = useState(false);
  const [loadingDistricts, setLoadingDistricts] = useState(false);
  const [loadingBranches, setLoadingBranches] = useState(false);
  const [geoData, setGeoData] = useState([]); // Stores all data from geozone.json
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadingImage, setUploadingImage] = useState(false);
  
  const toast = useToast();

  // Add state for payment status
  const [paymentStatus, setPaymentStatus] = useState({
    isProcessing: false,
    reference: '',
    isRetry: false
  });

  const [paymentTimer, setPaymentTimer] = useState(null);
  const [checkingPayment, setCheckingPayment] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredBranches, setFilteredBranches] = useState([]);

  // Add new state for timer
  const [timeRemaining, setTimeRemaining] = useState(60); // 60 seconds
  const [timerActive, setTimerActive] = useState(false);

  // Add this state at the top with other states
  const [hasAnimated, setHasAnimated] = useState(false);

  // Add this useEffect to handle initial animation
  useEffect(() => {
    setHasAnimated(true);
  }, []); // Empty dependency array means it only runs once on mount

  useEffect(() => {
    // Load geozone.json from the public directory
    const loadGeoData = async () => {
      setLoading(true);
      try {
        const response = await fetch('/geozone.json');
        if (!response.ok) {
          throw new Error('Failed to load geozone.json');
        }
        const data = await response.json();
        const geoItems = Array.isArray(data) ? data : data.responseObj?.items;
        if (!Array.isArray(geoItems)) {
          throw new Error('Invalid data format: expected an array');
        }
        setGeoData(geoItems);
        const zonesData = geoItems.filter(item => item.levelID === "level1");
        setZones(zonesData);
      } catch (error) {
        console.error('Error loading geographic data:', error);
        toast({ title: 'Error loading geographic data', status: 'error' });
      } finally {
        setLoading(false);
      }
    };
    loadGeoData();
  }, []);

  useEffect(() => {
    const loadBranches = async () => {
      setLoadingBranches(true);
      try {
        const response = await axios.get('https://yanga.jambosales.com/api/v2/club/list_branches');
        setBranches(response.data.responseObj?.items || []);
      } catch (error) {
        console.error('Error loading branches:', error);
        toast({
          title: 'Error loading branches',
          description: error.message,
          status: 'error'
        });
      } finally {
        setLoadingBranches(false);
      }
    };

    loadBranches();
  }, [toast]);

  useEffect(() => {
    if (!searchQuery.trim()) {
      setFilteredBranches(branches);
      return;
    }

    const filtered = branches.filter(branch => 
      branch.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      branch.code.toLowerCase().includes(searchQuery.toLowerCase()) ||
      branch.location.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredBranches(filtered);
  }, [searchQuery, branches]);

  const handleZoneChange = (e) => {
    const zoneId = e.target.value;
    console.log('Selected Zone ID:', zoneId);
    
    setFormData({ ...formData, selectedZone: zoneId, selectedCity: '', selectedDistrict: '', branchID: '' });
    setLoadingCities(true);
    
    const citiesData = geoData.filter(item => {
      console.log('Checking item:', item);
      console.log('Comparing:', {
        isLevel2: item.levelID === "level2",
        itemParentID: item.parentID,
        zoneId: Number(zoneId),
        matches: item.levelID === "level2" && item.parentID === Number(zoneId)
      });
      
      return item.levelID === "level2" && item.parentID === Number(zoneId);
    });
    
    console.log('Found cities:', citiesData);
    
    setCities(citiesData);
    setDistricts([]);
    setBranches([]);
    setLoadingCities(false);
  };

  const handleCityChange = (e) => {
    const cityId = e.target.value;
    setFormData({ ...formData, selectedCity: cityId, selectedDistrict: '', branchID: '' });
    setLoadingDistricts(true);
    const districtsData = geoData.filter(item => 
      item.levelID === "level3" && 
      item.parentID === Number(cityId)
    );
    setDistricts(districtsData);
    setBranches([]);
    setLoadingDistricts(false);
  };

  const handleDistrictChange = async (e) => {
    const districtId = e.target.options[e.target.selectedIndex].text;
    setFormData({ ...formData, selectedDistrict: districtId, branchID: '' });
    setLoadingBranches(true);
    try {
      const response = await axios.post(
        'https://yanga.jambosales.com/api/v2/club/list_branches',
        {
          limit: 500,
          offset: 0,
          autoAdd: "NO",
          location: "",
          orderBy: { key: "name", value: "ASC" },
          filter: [
            { key: "district", value: districtId, ttype: "==" },
            { key: "countMembers", value: 500, ttype: "<=" }
          ],
          appVersion: "1.51",
          platform: "ANDROID"
        }
      );
      setBranches(response.data.responseObj.items || []);
    } catch (error) {
      toast({ title: 'Error fetching branches', status: 'error' });
    } finally {
      setLoadingBranches(false);
    }
  };

  // Convert branches to options format
  const branchOptions = branches.map(branch => ({
    value: branch.id,
    label: `${branch.name} - ${branch.code}`,
    branch: branch // store full branch object
  }));

  // Update branch change handler
  const handleBranchChange = (selectedOption) => {
    if (selectedOption) {
      const selectedBranch = branches.find(branch => branch.id === selectedOption.value);
      if (selectedBranch) {
        setFormData(prev => ({
          ...prev,
          branchID: selectedBranch.code,
          branchName: selectedBranch.name,
          zone: selectedBranch.zone,
          region: selectedBranch.region,
          district: selectedBranch.district
        }));
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const payload = {
        email: `me_${formData.phone}@mail.com`,
        username: formData.phone,
        phone: formData.phone,
        payment_phone: formData.payment_phone,
        fName: formData.fName,
        lName: formData.lName,
        branchID: formData.branchID,
        branchName: formData.branchName,
        dob: formData.dob,
        gender: formData.gender.toUpperCase(),
        country: formData.country,
        zone: formData.zone,
        region: formData.region,
        district: formData.district,
        mno: formData.mno
      };

      const response = await axios.post(
        `${API_URL}/api/register-member/`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data.status === "success") {
        setPaymentStatus({
          isProcessing: true,
          reference: response.data.reference,
          isRetry: response.data.is_retry
        });
        setTimeRemaining(60);
        setTimerActive(true);
      }

      toast({
        title: 'Please check your phone for payment prompt',
        status: 'info',
        duration: null // Keep it visible
      });

    } catch (error) {
      console.error('Submit Error:', error);
      toast({
        title: 'Registration Error',
        description: error.response?.data?.message || error.message,
        status: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const checkPaymentStatus = async () => {
    if (checkingPayment) return;
    
    setCheckingPayment(true);
    try {
      const response = await axios.post(
        `${API_URL}/api/check-member-registration/`,
        { phone: formData.phone },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data.is_registered) {
        setPaymentStatus(prev => ({ ...prev, isProcessing: false }));
        toast({
          title: 'Registration Successful!',
          description: 'Your payment has been confirmed',
          status: 'success',
          duration: 5000
        });
        // Optionally redirect or reset form
      }

    } catch (error) {
      console.error('Payment check error:', error);
    } finally {
      setCheckingPayment(false);
    }
  };

  const startPaymentCheck = () => {
    // Check every 10 seconds
    const timer = setInterval(checkPaymentStatus, 10000);
    setPaymentTimer(timer);
  };

  // Clean up timer on component unmount
  useEffect(() => {
    return () => {
      if (paymentTimer) {
        clearInterval(paymentTimer);
      }
    };
  }, [paymentTimer]);

  const isFormComplete = () => {
    console.log('Checking form completion:', {
      phone: !!formData.phone,
      payment_phone: !!formData.payment_phone,
      fName: !!formData.fName,
      lName: !!formData.lName,
      dob: !!formData.dob,
      gender: !!formData.gender,
      branchID: !!formData.branchID,
      zone: !!formData.zone,
      region: !!formData.region,
      district: !!formData.district
    });

    return (
      formData.phone?.trim() &&
      formData.payment_phone?.trim() &&
      formData.fName?.trim() &&
      formData.lName?.trim() &&
      formData.dob &&
      formData.gender?.trim() &&
      formData.branchID?.trim() &&
      formData.zone?.trim() &&
      formData.region?.trim() &&
      formData.district?.trim()
    );
  };

  const handleDateChange = (date) => {
    if (date) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      const formattedDate = `${day}/${month}/${year}`;
      setFormData({ ...formData, dob: formattedDate });
    } else {
      setFormData({ ...formData, dob: '' });
    }
  };

  // Timer effect
  useEffect(() => {
    let interval;
    if (timerActive && timeRemaining > 0) {
      interval = setInterval(() => {
        setTimeRemaining((prev) => prev - 1);
      }, 1000);
    } else if (timeRemaining === 0) {
      setPaymentStatus(prev => ({
        ...prev,
        isProcessing: false,
        isRetry: true
      }));
      toast({
        title: 'Payment timeout',
        description: 'Please try again with your payment number',
        status: 'warning',
      });
    }

    return () => clearInterval(interval);
  }, [timerActive, timeRemaining, toast]);

  // Wrap the motion components with proper types
  const MotionBox = motion(Box);
  const MotionVStack = motion(VStack);

  // Updated PaymentStatusDisplay component
  const PaymentStatusDisplay = () => {
    if (!paymentStatus.isProcessing) return null;

    return (
      <Box 
        p={6} 
        bg="white" 
        borderRadius="xl" 
        boxShadow="xl"
        maxW="md"
        mx="auto"
        textAlign="center"
        {...(!hasAnimated ? {
          as: motion.div,
          initial: { opacity: 0, y: 20 },
          animate: { opacity: 1, y: 0 },
          transition: { duration: 0.5 }
        } : {})}
      >
        <VStack spacing={6}>
          {/* Timer Display */}
          <Box position="relative">
            <CircularProgress
              value={(timeRemaining / 60) * 100}
              size="120px"
              thickness="8px"
              color="teal.400"
            >
              <CircularProgressLabel>
                <VStack spacing={0}>
                  <Text fontSize="xl" fontWeight="bold">
                    {timeRemaining}s
                  </Text>
                  <Text fontSize="xs" color="gray.500">
                    remaining
                  </Text>
                </VStack>
              </CircularProgressLabel>
            </CircularProgress>
          </Box>

          <VStack spacing={3}>
            <Heading size="md" color="gray.700">
              Payment Processing
            </Heading>
            
            <HStack>
              <PhoneIcon color="teal.500" />
              <Text color="gray.600">
                Check your phone
                <Text as="span" fontWeight="bold" ml={1}>
                  ({formData.payment_phone})
                </Text>
              </Text>
            </HStack>

            <Text fontSize="sm" color="gray.500">
              Reference: {paymentStatus.reference}
            </Text>
          </VStack>

          <Divider />

          <VStack spacing={4} width="full">
            <Button
              leftIcon={<RepeatIcon />}
              colorScheme="teal"
              onClick={checkPaymentStatus}
              isLoading={checkingPayment}
              width={["full", "auto"]}
              disabled={timeRemaining === 0}
            >
              Check Payment Status
            </Button>

            {(timeRemaining === 0 || paymentStatus.isRetry) && (
              <Button
                variant="outline"
                colorScheme="gray"
                width={["full", "auto"]}
                onClick={() => {
                  setPaymentStatus({
                    isProcessing: false,
                    reference: '',
                    isRetry: false
                  });
                  setTimerActive(false);
                  setTimeRemaining(60);
                }}
              >
                Change Payment Number
              </Button>
            )}
          </VStack>

          {timeRemaining === 0 && (
            <Alert status="warning" borderRadius="md">
              <AlertIcon />
              <AlertTitle>Payment Time Expired</AlertTitle>
              <AlertDescription>
                Please try again with your payment number
              </AlertDescription>
            </Alert>
          )}
        </VStack>
      </Box>
    );
  };

  return (
    <Center minH="100vh" bg="gray.100">
      <Box w={['90%', '80%', '60%']} p={6} bg="white" boxShadow="lg" borderRadius="lg">
        <Heading mb={6} textAlign="center">Register Member</Heading>
        
        {paymentStatus.isProcessing ? (
          <PaymentStatusDisplay />
        ) : (
          <MotionVStack
            key="registration-form"
            as="form"
            spacing={6}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <FormControl>
              <FormLabel>Phone Number</FormLabel>
              <Input name="phone" value={formData.phone} onChange={(e) => setFormData({ ...formData, phone: e.target.value })} required />
            </FormControl>
            <FormControl>
              <FormLabel>Payment Phone</FormLabel>
              <Input name="payment_phone" value={formData.payment_phone} onChange={(e) => setFormData({ ...formData, payment_phone: e.target.value })} required />
            </FormControl>
            <FormControl>
              <FormLabel>First Name</FormLabel>
              <Input name="fName" value={formData.fName} onChange={(e) => setFormData({ ...formData, fName: e.target.value })} required />
            </FormControl>
            <FormControl>
              <FormLabel>Last Name</FormLabel>
              <Input name="lName" value={formData.lName} onChange={(e) => setFormData({ ...formData, lName: e.target.value })} required />
            </FormControl>
            <FormControl>
              <FormLabel>Date of Birth</FormLabel>
              <DatePicker
                selected={formData.dob ? 
                  // Convert DD/MM/YYYY to Date object
                  (() => {
                    const [day, month, year] = formData.dob.split('/');
                    return new Date(year, month - 1, day);
                  })() 
                  : null
                }
                onChange={handleDateChange}
                dateFormat="dd/MM/yyyy"
                maxDate={new Date()}
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={100}
                placeholderText="Select date of birth"
                className="chakra-input css-1kp110w"
                required
              />
            </FormControl>
            <FormControl>
              <FormLabel>Gender</FormLabel>
              <Select name="gender" value={formData.gender} onChange={(e) => setFormData({ ...formData, gender: e.target.value })} required>
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Zone</FormLabel>
              <Select 
                name="selectedZone" 
                value={formData.selectedZone} 
                onChange={handleZoneChange} 
                required
              >
                <option value="">Select Zone</option>
                {zones.map(zone => (
                  <option key={zone.id} value={zone.id}>{zone.name}</option>
                ))}
              </Select>
            </FormControl>
            <FormControl isDisabled={!formData.selectedZone}>
              <FormLabel>City</FormLabel>
              {loadingCities ? <Spinner /> : (
                <Select name="selectedCity" value={formData.selectedCity} onChange={handleCityChange} required>
                  <option value="">Select City</option>
                  {cities.map(city => (
                    <option key={city.id} value={city.id}>{city.name}</option>
                  ))}
                </Select>
              )}
            </FormControl>
            <FormControl isDisabled={!formData.selectedCity}>
              <FormLabel>District</FormLabel>
              {loadingDistricts ? <Spinner /> : (
                <Select name="selectedDistrict" value={formData.selectedDistrict} onChange={handleDistrictChange} required>
                  <option value="">Select District</option>
                  {districts.map(district => (
                    <option key={district.id} value={district.name}>{district.name}</option>
                  ))}
                </Select>
              )}
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Branch</FormLabel>
              <ReactSelect
                options={branchOptions}
                onChange={handleBranchChange}
                placeholder="Search and select branch"
                isClearable
                isSearchable
                value={branchOptions.find(
                  option => option.value === branches.find(
                    b => b.code === formData.branchID
                  )?.id
                )}
                chakraStyles={{
                  dropdownIndicator: (provided) => ({
                    ...provided,
                    bg: 'transparent',
                    px: 2,
                    cursor: 'pointer',
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    bg: state.isSelected ? 'teal.500' : 
                        state.isFocused ? 'teal.50' : 'transparent',
                    color: state.isSelected ? 'white' : 'black',
                  })
                }}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Payment Phone Number</FormLabel>
              <Input
                name="payment_phone"
                value={formData.payment_phone}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  payment_phone: e.target.value
                }))}
                placeholder="Enter M-Pesa number"
                required
              />
            </FormControl>
            <Button
              mt={4}
              colorScheme="teal"
              isLoading={loading}
              type="submit"
              width="full"
              onClick={(e) => {
                e.preventDefault();
                console.log('Submit clicked');
                console.log('Form Data:', formData);
                if (isFormComplete()) {
                  handleSubmit(e);
                } else {
                  toast({
                    title: 'Form Incomplete',
                    description: 'Please fill in all required fields',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                  });
                }
              }}
              disabled={!isFormComplete() || loading}
            >
              Register
            </Button>
          </MotionVStack>
        )}
      </Box>
    </Center>
  );
};

export default RegisterMember;
