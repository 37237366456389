import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Center,
  CircularProgress,
  CircularProgressLabel,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  VStack,
  Text,
  useToast,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  RadioGroup,
  Radio,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';
import { PhoneIcon, RepeatIcon } from '@chakra-ui/icons';
import { motion } from 'framer-motion';
import axios from 'axios';
import { API_URL } from '../../utils/api/Api';


const TopUpAccount = () => {
  const [searchParams] = useSearchParams();
  const toast = useToast();

  // States
  const [loading, setLoading] = useState(false);
  const [hasAnimated, setHasAnimated] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(60);
  const [timerActive, setTimerActive] = useState(false);
  const [checkingPayment, setCheckingPayment] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [initialLoading, setInitialLoading] = useState(true);
  
  const [formData, setFormData] = useState({
    wallet_phone: formatPhoneNumber(searchParams.get('phone_number') || ''),
    payment_phone: formatPhoneNumber(searchParams.get('phone_number') || ''),
    amount: 5000,
    mno: 'Vodacom',
    user_id: null
  });

  const [paymentStatus, setPaymentStatus] = useState({
    isProcessing: false,
    reference: '',
    isRetry: false
  });

  // Predefined amounts
  const predefinedAmounts = [
    { value: 5000, label: '5,000 TZS' },
    { value: 10000, label: '10,000 TZS' },
    { value: 20000, label: '20,000 TZS' },
    { value: 50000, label: '50,000 TZS' },
    { value: 100000, label: '100,000 TZS' },
  ];

  // Format phone number function
  function formatPhoneNumber(phoneNumber) {
    const cleanNumber = phoneNumber.replace(/\s+/g, '');
    if (cleanNumber.startsWith('+255')) {
      return '0' + cleanNumber.slice(4);
    }
    if (cleanNumber.startsWith('255')) {
      return '0' + cleanNumber.slice(3);
    }
    if (cleanNumber.startsWith('0')) {
      return cleanNumber;
    }
    return phoneNumber;
  }

  // Timer effect
  useEffect(() => {
    let interval;
    if (timerActive && timeRemaining > 0) {
      interval = setInterval(() => {
        setTimeRemaining((prev) => prev - 1);
      }, 1000);
    } else if (timeRemaining === 0) {
      setPaymentStatus(prev => ({
        ...prev,
        isProcessing: false,
        isRetry: true
      }));
      toast({
        title: 'Payment timeout',
        description: 'Please try again with your payment number',
        status: 'warning',
      });
    }

    return () => clearInterval(interval);
  }, [timerActive, timeRemaining, toast]);

  // Handle top up submission
  const handleTopUp = async () => {
    setLoading(true);
    try {
      const payload = {
        phone: formData.wallet_phone.startsWith('0') ? 
          '255' + formData.wallet_phone.slice(1) : formData.wallet_phone,
        payment_phone: formData.payment_phone.startsWith('0') ? 
          '255' + formData.payment_phone.slice(1) : formData.payment_phone,
        amount: formData.amount,
        mno: formData.mno,
        user_id: formData.user_id
      };

      console.log('Sending payload:', payload);

      const response = await axios.post(
        `${API_URL}/api/wallet/topup/`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data.status === "success") {
        setPaymentStatus({
          isProcessing: true,
          reference: response.data.reference,
          isRetry: false
        });
        setTimeRemaining(60);
        setTimerActive(true);
        
        toast({
          title: 'Payment Initiated',
          description: 'Please check your phone for payment prompt',
          status: 'info',
          duration: null
        });
      } else {
        throw new Error(response.data.message);
      }

    } catch (error) {
      console.error('Top Up Error:', error);
      toast({
        title: 'Top Up Error',
        description: error.response?.data?.message || error.message,
        status: 'error',
        duration: 5000
      });
    } finally {
      setLoading(false);
    }
  };

  // Check payment status
  const checkPaymentStatus = async () => {
    if (checkingPayment) return;
    
    setCheckingPayment(true);
    try {
      const response = await axios.post(
        `${API_URL}/api/wallet/check_status/`,
        { reference: paymentStatus.reference },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      switch (response.data.status) {
        case "success":
          setPaymentStatus(prev => ({ ...prev, isProcessing: false }));
          toast({
            title: 'Top Up Successful!',
            description: `Your account has been credited with ${response.data.amount} TZS`,
            status: 'success',
            duration: 5000
          });
          setTimerActive(false);
          break;

        case "pending":
          toast({
            title: 'Payment Pending',
            description: response.data.message,
            status: 'info',
            duration: 3000
          });
          break;

        case "failed":
          setPaymentStatus(prev => ({ 
            ...prev, 
            isProcessing: false,
            isRetry: true 
          }));
          toast({
            title: 'Payment Failed',
            description: response.data.message,
            status: 'error',
            duration: 5000
          });
          setTimerActive(false);
          break;

        default:
          throw new Error('Unknown payment status');
      }

    } catch (error) {
      console.error('Payment check error:', error);
      toast({
        title: 'Error Checking Payment',
        description: error.response?.data?.message || error.message,
        status: 'error',
        duration: 5000
      });
    } finally {
      setCheckingPayment(false);
    }
  };

  // Add automatic payment checking
  useEffect(() => {
    let checkInterval;
    
    if (paymentStatus.isProcessing && timeRemaining > 0) {
      checkInterval = setInterval(() => {
        checkPaymentStatus();
      }, 5000); // Check every 5 seconds
    }

    return () => {
      if (checkInterval) {
        clearInterval(checkInterval);
      }
    };
  }, [paymentStatus.isProcessing, timeRemaining]);

  // Add useEffect to fetch user info on mount
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const phone = searchParams.get('phone_number');
        if (!phone) {
          toast({
            title: 'Error',
            description: 'No phone number provided',
            status: 'error',
            duration: 5000
          });
          return;
        }

        const response = await axios.post(
          `${API_URL}/api/get-member-info/`,
          {
            filter: [
              { key: "mobileNumber", value: formatPhoneNumber(phone), ttype: "==" }
            ]
          },
          {
            headers: {
              'Authorization': 'Bearer',
              'Content-Type': 'application/json'
            }
          }
        );

        if (response.data.responseObj.items.length > 0) {
          const user = response.data.responseObj.items[0];
          setUserInfo(user);
          setFormData(prev => ({
            ...prev,
            wallet_phone: formatPhoneNumber(phone),
            payment_phone: formatPhoneNumber(phone),
            user_id: user.id // Add user ID to form data
          }));
        } else {
          toast({
            title: 'User not found',
            description: 'No user found with this phone number',
            status: 'error',
            duration: 5000
          });
        }
      } catch (error) {
        console.error('Error fetching user info:', error);
        toast({
          title: 'Error',
          description: 'Failed to fetch user information',
          status: 'error',
          duration: 5000
        });
      } finally {
        setInitialLoading(false);
      }
    };

    fetchUserInfo();
  }, [searchParams, toast]);

  // Payment Status Display Component
  const PaymentStatusDisplay = () => {
    if (!paymentStatus.isProcessing) return null;

    return (
      <Box 
        p={6} 
        bg="white" 
        borderRadius="xl" 
        boxShadow="xl"
        maxW="md"
        mx="auto"
        textAlign="center"
        {...(!hasAnimated ? {
          as: motion.div,
          initial: { opacity: 0, y: 20 },
          animate: { opacity: 1, y: 0 },
          transition: { duration: 0.5 }
        } : {})}
      >
        <VStack spacing={6}>
          <Box position="relative">
            <CircularProgress
              value={(timeRemaining / 60) * 100}
              size="120px"
              thickness="8px"
              color="teal.400"
            >
              <CircularProgressLabel>
                <VStack spacing={0}>
                  <Text fontSize="xl" fontWeight="bold">
                    {timeRemaining}s
                  </Text>
                  <Text fontSize="xs" color="gray.500">
                    remaining
                  </Text>
                </VStack>
              </CircularProgressLabel>
            </CircularProgress>
          </Box>

          <VStack spacing={3}>
            <Heading size="md" color="gray.700">
              Payment Processing
            </Heading>
            
            <HStack>
              <PhoneIcon color="teal.500" />
              <Text color="gray.600">
                Check your phone
                <Text as="span" fontWeight="bold" ml={1}>
                  ({formData.payment_phone})
                </Text>
              </Text>
            </HStack>

            <Text fontSize="sm" color="gray.500">
              Amount: {formData.amount.toLocaleString()} TZS
            </Text>
            <Text fontSize="sm" color="gray.500">
              Reference: {paymentStatus.reference}
            </Text>
          </VStack>

          <Divider />

          <VStack spacing={4} width="full">
            <Button
              leftIcon={<RepeatIcon />}
              colorScheme="teal"
              onClick={checkPaymentStatus}
              isLoading={checkingPayment}
              width={["full", "auto"]}
              disabled={timeRemaining === 0}
            >
              Check Payment Status
            </Button>

            {(timeRemaining === 0 || paymentStatus.isRetry) && (
              <Button
                variant="outline"
                colorScheme="gray"
                width={["full", "auto"]}
                onClick={() => {
                  setPaymentStatus({
                    isProcessing: false,
                    reference: '',
                    isRetry: false
                  });
                  setTimerActive(false);
                  setTimeRemaining(60);
                }}
              >
                Try Again
              </Button>
            )}
          </VStack>

          {timeRemaining === 0 && (
            <Alert status="warning" borderRadius="md">
              <AlertIcon />
              <AlertTitle>Payment Time Expired</AlertTitle>
              <AlertDescription>
                Please try again
              </AlertDescription>
            </Alert>
          )}
        </VStack>
      </Box>
    );
  };

  return (
    <Center minH="100vh" bg="gray.100">
      <Box w={['90%', '80%', '60%']} p={6} bg="white" boxShadow="lg" borderRadius="lg">
        <Heading mb={6} textAlign="center">Top Up Account</Heading>
        
        {initialLoading ? (
          <Center p={8}>
            <VStack spacing={4}>
              <CircularProgress isIndeterminate color="teal.400" />
              <Text color="gray.600">Loading user information...</Text>
            </VStack>
          </Center>
        ) : !userInfo ? (
          <Alert status="error" borderRadius="md">
            <AlertIcon />
            <VStack align="start">
              <AlertTitle>User Not Found</AlertTitle>
              <AlertDescription>
                Could not find user information. Please try again later.
              </AlertDescription>
            </VStack>
          </Alert>
        ) : paymentStatus.isProcessing ? (
          <PaymentStatusDisplay />
        ) : (
          <VStack spacing={6}>
            {/* User Info Display */}
            <Box w="full" p={4} bg="gray.50" borderRadius="md">
              <VStack align="start" spacing={2}>
                <Text fontWeight="bold">Account Information</Text>
                <Text fontSize="sm">Name: {userInfo.fName} {userInfo.lName}</Text>
                <Text fontSize="sm">Member ID: {userInfo.memberID}</Text>
                <Text fontSize="sm">Branch: {userInfo.branchName}</Text>
              </VStack>
            </Box>

            {/* Rest of the form fields */}
            <FormControl>
              <FormLabel>Wallet Account</FormLabel>
              <Input
                value={formData.wallet_phone}
                isReadOnly
                bg="gray.50"
                _hover={{ cursor: 'not-allowed' }}
                _focus={{ borderColor: 'gray.300' }}
              />
              <Text fontSize="sm" color="gray.500" mt={1}>
                This is your wallet account number
              </Text>
            </FormControl>

            <FormControl>
              <FormLabel>Payment Phone Number</FormLabel>
              <Input
                value={formData.payment_phone}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  payment_phone: formatPhoneNumber(e.target.value)
                }))}
                placeholder="Enter M-Pesa number"
              />
              <Text fontSize="sm" color="gray.500" mt={1}>
                The number that will be used for payment
              </Text>
            </FormControl>

            <FormControl>
              <FormLabel>Select Amount</FormLabel>
              <RadioGroup
                value={formData.amount.toString()}
                onChange={(value) => setFormData(prev => ({
                  ...prev,
                  amount: parseInt(value)
                }))}
              >
                <VStack align="start" spacing={3}>
                  {predefinedAmounts.map((amount) => (
                    <Radio key={amount.value} value={amount.value.toString()}>
                      {amount.label}
                    </Radio>
                  ))}
                </VStack>
              </RadioGroup>
            </FormControl>

            <FormControl>
              <FormLabel>Custom Amount</FormLabel>
              <NumberInput
                min={1000}
                max={1000000}
                value={formData.amount}
                onChange={(valueString) => setFormData(prev => ({
                  ...prev,
                  amount: parseInt(valueString)
                }))}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <Text fontSize="sm" color="gray.500" mt={1}>
                Min: 1,000 TZS | Max: 1,000,000 TZS
              </Text>
            </FormControl>

            <Button
              colorScheme="teal"
              width="full"
              onClick={handleTopUp}
              isLoading={loading}
              disabled={!formData.payment_phone || formData.amount < 1000}
            >
              Top Up {formData.amount.toLocaleString()} TZS
            </Button>
          </VStack>
        )}
      </Box>
    </Center>
  );
};

export default TopUpAccount;
