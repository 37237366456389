export const formatPhoneNumber = (phoneNumber) => {
    // Remove any spaces or special characters
    const cleanNumber = phoneNumber.replace(/\s+/g, '');
    
    // Check if number starts with +255
    if (cleanNumber.startsWith('+255')) {
      return '0' + cleanNumber.slice(4);
    }
    
    // Check if number starts with 255 without +
    if (cleanNumber.startsWith('255')) {
      return '0' + cleanNumber.slice(3);
    }
    
    // If number already starts with 0, return as is
    if (cleanNumber.startsWith('0')) {
      return cleanNumber;
    }
    
    // If none of the above, return the original number
    return phoneNumber;
  };
  