import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Center,
  CircularProgress,
  CircularProgressLabel,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  VStack,
  Text,
  useToast,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,

  Image
} from '@chakra-ui/react';

import { useSearchParams } from 'react-router-dom';
import { PhoneIcon, RepeatIcon } from '@chakra-ui/icons';
import { Spinner } from '@chakra-ui/react';
import axios from 'axios';
import { motion } from 'framer-motion';
import { formatPhoneNumber } from '../../utils/phonenumber/PhoneNumber';
import { API_URL } from '../../utils/api/Api';


const RenewMember = () => {
  const [searchParams] = useSearchParams();
  const initialPhoneNumber = formatPhoneNumber( searchParams.get('phone_number') || '');
  console.log("initialPhoneNumber",initialPhoneNumber)
  const toast = useToast();

  // States
  const [memberInfo, setMemberInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paymentPhone, setPaymentPhone] = useState(initialPhoneNumber);
  const [timeRemaining, setTimeRemaining] = useState(60);
  const [timerActive, setTimerActive] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState({
    isProcessing: false,
    reference: '',
    isRetry: false
  });
  const [checkingPayment, setCheckingPayment] = useState(false);
  const [hasAnimated, setHasAnimated] = useState(false);

  // Fetch member info on component mount
  useEffect(() => {
    const fetchMemberInfo = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${API_URL}/api/get-member-info/`,
          {
            filter: [
              { key: "mobileNumber", value: initialPhoneNumber, ttype: "==" }
            ]
          },
          {
            headers: {
              'Authorization': 'Bearer',
              'Content-Type': 'application/json'
            }
          }
        );

        if (response.data.responseObj.items.length > 0) {
          setMemberInfo(response.data.responseObj.items[0]);
        } else {
          toast({
            title: 'Member not found',
            description: 'No member found with this phone number',
            status: 'error',
            duration: 5000
          });
        }
      } catch (error) {
        console.error('Error fetching member info:', error);
        toast({
          title: 'Error',
          description: 'Failed to fetch member information',
          status: 'error',
          duration: 5000
        });
      } finally {
        setLoading(false);
      }
    };

    if (initialPhoneNumber) {
      fetchMemberInfo();
    }
  }, [initialPhoneNumber, toast]);

  // Handle renewal submission
  const handleRenewal = async () => {
    setLoading(true);
    try {
      const payload = {
        phone: memberInfo.mobile,
        payment_phone: paymentPhone,
        member_id: memberInfo.memberID,
        branch_id: memberInfo.branchID,
        branch_name: memberInfo.branchName,
        mno: "Vodacom" // Or determine from phone number
      };

      const response = await axios.post(
        `${API_URL}/api/renew-member/`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data.status === "success") {
        setPaymentStatus({
          isProcessing: true,
          reference: response.data.reference,
          isRetry: response.data.is_retry
        });
        setTimeRemaining(60);
        setTimerActive(true);
        
        toast({
          title: 'Please check your phone for payment prompt',
          status: 'info',
          duration: null
        });
      }

    } catch (error) {
      console.error('Renewal Error:', error);
      toast({
        title: 'Renewal Error',
        description: error.response?.data?.message || error.message,
        status: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  // Check payment status
  const checkPaymentStatus = async () => {
    if (checkingPayment) return;
    
    setCheckingPayment(true);
    try {
      const response = await axios.post(
        `${API_URL}/api/get-member-info/`,
        {
          filter: [
            { key: "mobileNumber", value: memberInfo.mobile, ttype: "==" }
          ]
        },
        {
          headers: {
            'Authorization': 'Bearer',
            'Content-Type': 'application/json'
          }
        }
      );

      const updatedMemberInfo = response.data.responseObj.items[0];
      if (updatedMemberInfo.expiryStatus === "ACTIVE") {
        setPaymentStatus(prev => ({ ...prev, isProcessing: false }));
        toast({
          title: 'Renewal Successful!',
          description: 'Your membership has been renewed',
          status: 'success',
          duration: 5000
        });
      }

    } catch (error) {
      console.error('Payment check error:', error);
    } finally {
      setCheckingPayment(false);
    }
  };

  // Timer effect
  useEffect(() => {
    let interval;
    if (timerActive && timeRemaining > 0) {
      interval = setInterval(() => {
        setTimeRemaining((prev) => prev - 1);
      }, 1000);
    } else if (timeRemaining === 0) {
      setPaymentStatus(prev => ({
        ...prev,
        isProcessing: false,
        isRetry: true
      }));
      toast({
        title: 'Payment timeout',
        description: 'Please try again with your payment number',
        status: 'warning',
      });
    }

    return () => clearInterval(interval);
  }, [timerActive, timeRemaining, toast]);

  // ... PaymentStatusDisplay component (similar to registration)
  const PaymentStatusDisplay = () => {
    if (!paymentStatus.isProcessing) return null;

    return (
      <Box 
        p={6} 
        bg="white" 
        borderRadius="xl" 
        boxShadow="xl"
        maxW="md"
        mx="auto"
        textAlign="center"
        {...(!hasAnimated ? {
          as: motion.div,
          initial: { opacity: 0, y: 20 },
          animate: { opacity: 1, y: 0 },
          transition: { duration: 0.5 }
        } : {})}
      >
        <VStack spacing={6}>
          {/* Timer Display */}
          <Box position="relative">
            <CircularProgress
              value={(timeRemaining / 60) * 100}
              size="120px"
              thickness="8px"
              color="teal.400"
            >
              <CircularProgressLabel>
                <VStack spacing={0}>
                  <Text fontSize="xl" fontWeight="bold">
                    {timeRemaining}s
                  </Text>
                  <Text fontSize="xs" color="gray.500">
                    remaining
                  </Text>
                </VStack>
              </CircularProgressLabel>
            </CircularProgress>
          </Box>

          <VStack spacing={3}>
            <Heading size="md" color="gray.700">
              Payment Processing
            </Heading>
            
            <HStack>
              <PhoneIcon color="teal.500" />
              <Text color="gray.600">
                Check your phone
                <Text as="span" fontWeight="bold" ml={1}>
                  ({paymentPhone})
                </Text>
              </Text>
            </HStack>

            <Text fontSize="sm" color="gray.500">
              Reference: {paymentStatus.reference}
            </Text>
          </VStack>

          <Divider />

          <VStack spacing={4} width="full">
            <Button
              leftIcon={<RepeatIcon />}
              colorScheme="teal"
              onClick={checkPaymentStatus}
              isLoading={checkingPayment}
              width={["full", "auto"]}
              disabled={timeRemaining === 0}
            >
              Check Payment Status
            </Button>

            {(timeRemaining === 0 || paymentStatus.isRetry) && (
              <Button
                variant="outline"
                colorScheme="gray"
                width={["full", "auto"]}
                onClick={() => {
                  setPaymentStatus({
                    isProcessing: false,
                    reference: '',
                    isRetry: false
                  });
                  setTimerActive(false);
                  setTimeRemaining(60);
                }}
              >
                Change Payment Number
              </Button>
            )}
          </VStack>

          {timeRemaining === 0 && (
            <Alert status="warning" borderRadius="md">
              <AlertIcon />
              <AlertTitle>Payment Time Expired</AlertTitle>
              <AlertDescription>
                Please try again with your payment number
              </AlertDescription>
            </Alert>
          )}
        </VStack>
      </Box>
    );
  };

  // Add useEffect for initial animation
  useEffect(() => {
    setHasAnimated(true);
  }, []);

  return (
    <Center minH="100vh" bg="gray.100">
      <Box w={['90%', '80%', '60%']} p={6} bg="white" boxShadow="lg" borderRadius="lg">
        <Heading mb={6} textAlign="center">Renew Membership</Heading>
        
        {loading ? (
          <Center p={8}>
            <Spinner size="xl" color='green' />
          </Center>
        ) : memberInfo ? (
          paymentStatus.isProcessing ? (
            <PaymentStatusDisplay />
          ) : (
            <VStack spacing={6}>
              {/* Member Info Display */}
              <Box w="full" p={4} borderRadius="md" bg="gray.50">
                <VStack align="start" spacing={3}>
                  <HStack>
                    <Image
                      src={memberInfo.profileImage}
                      alt={memberInfo.fName}
                      boxSize="100px"
                      borderRadius="full"
                      fallbackSrc="https://via.placeholder.com/100"
                    />
                    <VStack align="start">
                      <Text fontWeight="bold">{`${memberInfo.fName} ${memberInfo.lName}`}</Text>
                      <Text>Member ID: {memberInfo.memberID}</Text>
                      <Text>Branch: {memberInfo.branchName}</Text>
                      <Text>Status: {memberInfo.expiryStatus}</Text>
                    </VStack>
                  </HStack>
                </VStack>
              </Box>

              {/* Payment Phone Input */}
              <FormControl>
                <FormLabel>Payment Phone Number</FormLabel>
                <Input
                  value={paymentPhone}
                  onChange={(e) => setPaymentPhone(e.target.value)}
                  placeholder="Enter M-Pesa number"
                />
              </FormControl>

              <Button
                colorScheme="teal"
                width="full"
                onClick={handleRenewal}
                isLoading={loading}
                disabled={!paymentPhone}
              >
                Renew Membership
              </Button>
            </VStack>
          )
        ) : (
          <Text textAlign="center">No member information available</Text>
        )}
      </Box>
    </Center>
  );
};

export default RenewMember;
